// 常量
const constant = {
  GamePlay: 'https://futuredao.gitbook.io/jiaocheng',
  GameIntroduction: 'https://futuredao.gitbook.io/jiaocheng/youxijieshao',
  GamePlayEn: 'https://futuredao.gitbook.io/tutorial',
  GameIntroductionEn: 'https://futuredao.gitbook.io/tutorial/game-introduction',

  GMail: 'Game@chroniclesofwarcraft.com',

  MediumUrl: 'https://medium.com/@ChroniclesOfWarcraft',
  TelegramUrl: 'https://t.me/ChroniclesOfWarcraft',
  TwitterUrl: 'https://twitter.com/COWGameFi',
  YoutubeUrl: 'https://www.youtube.com/@COWGameFi'
}

export default {
  constant
}
