
export default {
  ComingSoon: 'Coming Soon',
  HeaderBar: {
    Dashboard: 'Dashboard',
    NFTMarket: 'NFTMarket',
    MysteryBox: 'MysteryBox',
    Knapsack: 'Knapsack',
    WhitePaper: 'WhitePaper',
    BeginnersGuide: 'Beginners Guide',
    GamePlay:"GamePlay",
    GameIntroduction:"Game Introduction"
  },
  GameDesc: {
    Desc: '"Chronicles of Warcraft" is a large-scale MMORPG 3D blockchain game integrating management, nurturing, and social interaction, published by Seven Circle. Focused on the theme of the beast clan community, it portrays the social, economic, and combat lives of the primordial divine beasts and guardian monsters, aiming to create a sustainable Web3 economic ecosystem in the metaverse.'
  },
  petArr: {
    PolarBearDesc: 'A beast native to cold regions, possessing a massive body and thick white fur, showcasing unparalleled strength. Its huge front claws are as hard as iron, capable of effortlessly tearing through sturdy foes.',
    SwiftEagleDesc: 'A swift bird of prey, each flap of its wings causing strong winds. Its pupils harbor sharp eyes, able to lock onto targets with precision, earning fame for its rapid speed.',
    CrystalWolfDesc: 'A mysterious wolf with a tail that shimmers with a mysterious light, capable of communicating with other beings through telepathy and manipulating the power of elements.',
    SavageDrakeDesc: 'A lurking dragon-like creature, massive and fearsome, covered in heavy scales. It boasts powerful jaw strength and sharp venomous fangs, with a tail as resilient as steel, each strike inflicting severe damage upon enemies.',
    StoneGolemDesc: 'A formidable magical creature, composed of tough rocks and potent magical material, able to draw strength from the earth, possessing unparalleled defensive capabilities.'
  },
  play: {
    DualToken: 'Dual-Token Mechanism',
    DualTokenDesc: 'The dual-token mechanism of $COW + $COC establishes asset and liquidity structures, supporting token economics to enhance ecosystem value.',
    NFTLands: 'NFT Lands',
    NFTLandsDesc: 'Become a landowner in the game and construct your territory! Use your land for planting, nurturing divine beasts, mining, and resource collection.',
    DIYLands: 'DIY Lands',
    DIYLandsDesc: 'The game offers high freedom, allowing you to unlock any area, name and customize your territory, providing a unique gaming experience for every user.',
    NFTCollect: 'NFT Collections',
    NFTCollectDesc: 'Collect divine beasts and various items in "chronicles of warcraft". Show off your collection to friends and other players!',
    Socialize: 'Personalized Social Interaction',
    SocializeDesc: 'You can customize your territory. Post articles, moods, and comments on the message board. Other players can visit your territory, repost, comment, and like.',
    Combat: 'Combat System',
    CombatDesc: 'Feed and breed divine beasts to obtain rare ones. Venture with your divine beasts to win lucrative rewards in PVP and PVE modes.',
    TokenStaking: 'Token Staking',
    TokenStakingDesc: 'All on-chain assets can be staked later to generate different value asset returns based on Staking.。',
    GlobalSystem: 'Global System',
    GlobalSystemDesc: 'Gather all players; your character image will also be displayed here. Chat, make friends, set up stalls, post tasks, engage in 1v1 bidding, and more.',
    DAO: 'DAO Governance',
    DAODesc: 'Hold $COW tokens and participate in game governance. "Chronicles of Warcraft" does not belong to an individual or a team; it belongs to the entire community organization members.',
    LuckyEggs: 'Lucky Eggs',
    LuckyEggsDesc: 'A unique lottery mechanism, dropping random surprises in farms or during PVE and PVP, wishing you good luck.'
  },
  roadMap: {
    Title2: '·2024·\nSecond Quarter',
    Title3: '·2024·\nThird Quarter',
    Title4: '·2024·\nFourth Quarter',
    Desc2: '·Release of Chronicles of Warcraft 1.0 version\n·Token listing on exchanges\n·Game trading module launch\n·Initiation of multiple rewards\n·Launch of NFT market\n·Sale of divine beast NFTs\n·Introduction of community program',
    Desc3: '·Release of "Chronicles of Warcraft" 2.0 version\n·Launch of character random NFTs\n·Internal testing of PVP module\n·Internal testing of social module\n·Internal testing of clan module\n·Internal testing of land NFTs\n·Update of world map',
    Desc4: '·Release of "Chronicles of Warcraft" 2.0 version\n·Launch of character random NFTs\n·Launch of PVP module\n·Launch of social module\n·Launch of clan module\n·Launch of land NFTs\n·Initiation of DAO governance'
  },
  team: {
    CeoDesc: 'A Ph.D. in Business Administration from Harvard University, an outstanding game developer, and entrepreneur. Founded the Indian game development company "Jump Games," releasing multiple successful games globally. Possesses exceptional technical and innovative abilities in the blockchain field, along with extensive experience and expertise in enterprise management.',
    CmoDesc: 'He holds a PhD from California University of Management MBA School of Business, more than 15 years of professional knowledge and experience in the financial field, and is a professional investor and business manager with the ability to deeply understand market dynamics and trends. Currently focusing on the cryptocurrency market, he has unique insights into blockchain and has participated in several blockchain gamefi projects.',
    CooDesc: 'A Ph.D. in Finance and Investment Management from the Indian Institute of Technology. Previously served as a financial analyst at the Indian Stock Exchange and holds CFA and FRM certifications. Combines expertise in the finance domain with blockchain technology to create business opportunities in the blockchain and financial sectors.',
    CtoDesc: 'A master’s degree in software engineering from the Indian Institute of Management. Previously held positions as a senior software engineer at renowned companies like Google and Blizzard. Passionate about gaming and dedicated to exploring new technologies and programming realms. Possesses solid experience in developing smart contracts and web3 technologies.'
  }
}
