import { createI18n } from 'vue-i18n'
import en from './en'
import sg from './sg'

const messages = { // 所有语言
  sg: { ...sg },
  en: { ...en }
}

const i18n = createI18n({
  locale: 'en', // 默认当前语言
  fallbackLocale: 'en', // 替补语言，比如上边locale语言显示不了了就显示这个语言
  messages, // 所有语言
  legacy: false, // 用于区分是否使用组合式api，v3使用要设为false
  silentFallbackWarn: true,
  silentTranslationWarn: true // 抑制警告+
  //global Injection 如果设置true, $t() 函数将注册到全局
})

export default i18n
