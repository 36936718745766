
export default {
  ComingSoon: '敬请期待',
  HeaderBar: {
    Dashboard: '首页',
    NFTMarket: 'NFT市场',
    MysteryBox: '神秘盒子',
    Knapsack: '背包',
    WhitePaper: '白皮书',
    BeginnersGuide: '新手入门',
    GamePlay:"游戏教程",
    GameIntroduction:"游戏介绍"
  },
  GameDesc: {
    Desc: '异兽志是一款兼经营养成、社交互动的大型MMORPG类3D区块链游戏，由Seven Circle公司发行。专注于以兽族社区为主题，其中创世神兽与守护怪兽的社会、经济和战斗生活，致力于打造可持续的Web3经济体系的元宇宙。'
  },
  petArr: {
    PolarBearDesc: '生活在寒冷地区的猛兽，它拥有庞大的身躯和厚实的白色毛皮，展现出无与伦比的力量。巨大的前爪如铁铸般坚硬，能够轻松撕碎坚固的敌人。',
    SwiftEagleDesc: '迅捷的鹰，每一次振翅都能引起强风。它的瞳孔中蕴藏着锋利的眼神，能够精准地锁定目标，以其迅猛的速度而闻名。',
    CrystalWolfDesc: '神秘的狼，尾巴闪烁着神秘的光芒，能够通过心灵感应与其他生物交流，并操纵元素之力。',
    SavageDrakeDesc: '蛰伏在暗处的蛮蛟，一种庞大而可怕的生物，覆盖着厚重的鳞片。它拥有强大的颚部力量和锋利的毒牙，尾巴如钢铁般坚韧，每一次袭击都能使敌人深受伤害。',
    StoneGolemDesc: '强悍的魔法生物，由坚硬的岩石和强大的魔法物质构成，可以从大地中吸取力量，得到无与伦比的防御力。'
  },
  play: {
    DualToken: '双通证机制',
    DualTokenDesc: '$COW+$COC的双通证机制，建立资产和流动性结构，支持通证经济，提升生态价值。',
    NFTLands: 'NFT领地',
    NFTLandsDesc: '成为游戏中的地主，建造自己的领地！使用您的土地种植、培养神兽、开采和收集资源等。',
    DIYLands: '领地DIY',
    DIYLandsDesc: '游戏自由度高，可以解锁任意区域，随意命名和DIY你的领地，一千名用户拥有一千个不同的游戏体验。',
    NFTCollect: 'NFT收藏',
    NFTCollectDesc: '可以在异兽志上收集神兽和各种物品。 快来向您的朋友和其他玩家展示您的收藏结果吧！',
    Socialize: '个性化社交',
    SocializeDesc: '你可以DIY你的领地。 您可以在留言板上发布文章、心情和评论。 其他玩家可以访问您的领地并转发、评论和点赞。',
    Combat: '战斗系统',
    CombatDesc: '喂养和繁殖神兽并获得稀有神兽。 你可以带着你的神兽去冒险，在 PVP 和 PVE 模式中赢取丰厚的奖励。',
    TokenStaking: '代币质押',
    TokenStakingDesc: '所有的链上资产后期都可以通过质押，根据Staking产生不同价值资产收益。',
    GlobalSystem: '大世界系统',
    GlobalSystemDesc: '聚集所有玩家，你的角色形象也会在这里展示。 可以聊天交友、摆摊、发布任务、1V1投注等。',
    DAO: 'DAO治理',
    DAODesc: '持有$COW代币，参与游戏治理。 异兽志不属于一个人或一个团队，它属于整个社区组织成员。',
    LuckyEggs: '幸运彩蛋',
    LuckyEggsDesc: '独特的抽奖机制，在农场或PVE和PVP中空投随机惊喜，祝您好运。'
  },
  roadMap: {
    Title2: '·2024·\n第二季度',
    Title3: '·2024·\n第三季度',
    Title4: '·2024·\n第四季度',
    Desc2: '·异兽志1.0版本上线\n·Token上线交易所\n·游戏交易模组上线\n·启动多重奖励\n·启动NFT市场\n·神兽NFT发售\n·推出奖学金计划',
    Desc3: '·异兽志2.0上线\n·人物随机NFT上线\n·PVP模组内部测试\n·社交模组内部测试\n·家族模组内部测试\n·土地NFT内部测试\n·世界大地图更新',
    Desc4: '·异兽志2.0上线\n·人物随机NFT上线\n·PVP模组上线\n·社交模组上线\n·家族模组上线\n·土地NFT上线\n·启动DAO治理'
  },
  team: {
    CeoDesc: '哈佛大学商亚管理博士学位，优秀的游戏开发者和企业家。创立了印度游戏开发公同“Jump Games”,在全球范国内发布了多款成功的游戏作品。具有区块链领域的卓越技术和创新能力以及企业管理方面深厚的经验和专业知识。',
    CmoDesc: '美国加州管理大学MBA商学院博士学位，15年以上金融领域的专业知识和经验，专业的投资人和企业管理人，具备深入了解市场动态和趋势的能力。目前专注于加密货币市场，对区块链有独到的见解，参与过数个区块链gamefi项目。',
    CooDesc: '印度理工学院金融与投资管理博士学位。曾任印度证券交易所任金融分析师职位，持有CFA和FRM证书。将金融领域的专业知识与区块链与金融业务结合创建区块链技术的商业机会。',
    CtoDesc: '印度管理学院软件工程硕士学位。曾任Google、Blizzard等知名企业高级软件工程师职位。热爱游戏，热衷于探索新技术和编程领域，具有扎实的智能合约和web3研发经验。'
  }
}
