// 正式配置
const common = {
  NFTMarketUrl: 'https://www.futuredao.io/market?tabv1=nft', // NFT市场外链
  MysteryBoxUrl: 'https://www.futuredao.io/market?tabv1=buylist', // 神秘盒子外链
  KnapsackUrl: 'https://www.futuredao.io/market?tabv1=inventory', // 背包外链
  NFTMintingUrl: 'https://www.futuredao.io/mystery', // NFT铸造外链

  GameUrl: 'https://gameplay.chroniclesofwarcraft.com' // 游戏地址
}
// 本地配置
const common_dev = {
  NFTMarketUrl: 'https://testnet.futuredao.io/market?tabv1=nft',
  MysteryBoxUrl: 'https://testnet.futuredao.io/market?tabv1=buylist',
  KnapsackUrl: 'https://testnet.futuredao.io/market?tabv1=inventory',
  NFTMintingUrl: 'https://testnet.futuredao.io/mystery',

  GameUrl: 'https://gameplay.chrcniclesofwarcraft.com'
}

export default {
  common: process.env.NODE_ENV === 'development' ? common_dev : common
}
