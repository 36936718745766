import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import common from '@/utils/common'
import constant from '@/utils/constant'

// import 'animate.css';

import '@/assets/style/index.scss'
import installElementPlus from './plugins/element'

import i18n from './lang/index.js'
// import AOS from 'aos'
// import 'aos/dist/aos.css'

// createApp(App).use(i18n).mount('#app')

const app = createApp(App)
// const appAOS = AOS.init({
// //   disable: false,
// //   startEvent: 'DOMContentLoaded',
// //   initClassName: 'fd-init',
// //   animatedClassName: 'aos-animate',
// //   useClassNames: false,
// //   disableMutationObserver: false,
// //   debounceDelay: 50,
// //   throttleDelay: 99,
// //   offset: 50,
// //   delay: 20,
// //   duration: 600,
// //   easing: 'ease',
// //   once: false,
// //   mirror: false,
// //   anchorPlacement: 'top-bottom'
// })

app.config.globalProperties.$common = common.common
app.config.globalProperties.$constant = constant.constant

installElementPlus(app)
app.use(i18n)
app.use(store).use(router).mount('#app')
// app.use(appAOS)
